import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import { getSymbols } from '../../../store/selectors/SymbolsSelectors'
import DashboardComboChart from '../Dashboard/Dashboard/DashboardComboChart'
import {
  getChartSeasonality,
  getChartCOT,
  getSymbolsByCandleForMarketData,
} from '../../../services/PostsService'
import { setLoading } from '../../../store/actions/LoadingActions'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { ColorType, PriceScaleMode, createChart } from 'lightweight-charts'
import Tooltip from './Tooltip'

export const ChartComponent = props => {
  const [tooltipData, setTooltipData] = useState({});
	const {
		firstData,
		secondData,
    firstChartRef,
    secondChartRef,
    syncCrosshairChart,
    syncCrosshairChartTime,
		colors: {
			backgroundColor = '#000',
			lineColor = ['#3dca7e', '#a52290', '#cbcf1f'],
			textColor = 'white',
			areaTopColor = '#2962FF',
			areaBottomColor = 'rgba(41, 98, 255, 0.28)',
		} = {},
	} = props;
  const firstChartTooltipRef = useRef();
  const secondChartTooltipRef = useRef();
  const { t } = useTranslation()

  const setTooltipPosition = (param, lineChart, firstSeriesLine, thirdSeriesLine, candelsChart, secondParam, seriesCandel, type) => {

    const toolTipWidth = 100;
    const toolTipHeight = 80;
    const toolTipMargin = 70;
    const toolTipVMargin = 20;

    // Create and style the tooltip html element
    const toolTipLine = secondChartTooltipRef.current;
    const toolTipCandels = firstChartTooltipRef.current;
    ///
    const timeCandels = candelsChart.timeScale();
    const timeLine = lineChart.timeScale();

    if (
      param.point === undefined ||
      !param.time ||
      param.point.x < 0 ||
      param.point.x > secondChartRef.current.clientWidth ||
      param.point.y < 0 ||
      param.point.y > secondChartRef.current.clientHeight
    ) {
      toolTipLine.style.display = 'none';
      toolTipCandels.style.display = 'none';
    } else {
      // time will be in the same format that we supplied to setData.
      // thus it will be YYYY-MM-DD
      let lineTime;
      let price1;
      let price2;
      let price3;
      //
      let dataLine = {};
      let dataCandels = {};

      if (type == 'line') {
        dataLine.firstDataLine = param.seriesData.get(firstSeriesLine);
        // dataLine.secondDataLine = param.seriesData.get(secondSeriesLine);
        dataLine.thirdSeriesLine = param.seriesData.get(thirdSeriesLine);

        dataCandels = secondParam;
        
        lineTime = param.time;
        price1 = dataLine.firstDataLine.value !== undefined ? dataLine.firstDataLine.value : dataLine.firstDataLine.close;
        // price2 = dataLine.secondDataLine.value !== undefined ? dataLine.secondDataLine.value : dataLine.secondDataLine.close;
        price3 = dataLine.thirdSeriesLine.value !== undefined ? dataLine.thirdSeriesLine.value : dataLine.thirdSeriesLine.close;
      } else {
        dataLine.firstDataLine = secondData[0].find(it => it.time == secondParam.time);
        // dataLine.secondDataLine = secondData[1].find(it => it.time == secondParam.time);
        dataLine.thirdSeriesLine = secondData[1].find(it => it.time == secondParam.time);
        lineTime = secondParam.time;

        price1 = dataLine.firstDataLine.value;
        // price2 = dataLine.secondDataLine.value;
        price3 = dataLine.thirdSeriesLine.value;
        dataCandels = param.seriesData.get(seriesCandel)
      }

      //
      toolTipLine.style = `transform: 0.5s all; min-width: 96px; min-height: 80px; position: absolute; display: none; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 8px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
      toolTipLine.style.background = '#060606cc';
      toolTipLine.style.color = 'white';
      toolTipLine.style.borderColor = '#000';
      toolTipLine.style.display = 'block';

      toolTipCandels.style = `transform: 0.5s all; min-width: 96px; min-height: 80px; position: absolute; display: none; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 8px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
      toolTipCandels.style.background = '#060606cc';
      toolTipCandels.style.color = 'white';
      toolTipCandels.style.borderColor = '#000';
      toolTipCandels.style.display = 'block';
    
      // const price3 = dataCandels.value !== undefined ? dataCandels.value : dataCandels.close;
      toolTipLine.innerHTML = `<div>
        <div style="border-radius: 8px 8px 0 0; min-height: 25px; background: #060606; padding: 8px;">
          ${lineTime}
        </div>
        <div style="padding: 8px;text-wrap: nowrap;">
          <div className="body" style="display: flex">
            <div style="border-radius: 50%; background: ${lineColor[0]}; margin-top: 3px; margin-right: 3px; width: 10px; height: 10px;"></div>
            ${t('commitmentOfTraders.commercial')}: ${price1}
          </div>
          <div className="body" style="display: flex">
            <div style="border-radius: 50%; background: ${lineColor[2]}; margin-top: 3px; margin-right: 3px; width: 10px; height: 10px;"></div>
            ${t('commitmentOfTraders.noReportables')}: ${price3}
          </div>
        </div>
      </div>`;
      const coordinate = firstSeriesLine.priceToCoordinate(price1);
      let shiftedCoordinate = timeLine.timeToCoordinate(lineTime);
      if (coordinate === null) {
        return;
      }
      const secondTooltipWidth = secondChartTooltipRef.current.clientWidth;
      const secondTooltipHeight = secondChartTooltipRef.current.clientHeight;
      let leftLine = shiftedCoordinate + toolTipMargin;
      if (leftLine > secondChartRef.current.clientWidth - secondTooltipWidth) {
        leftLine = shiftedCoordinate - secondTooltipWidth + 40;
      }
      const coordinateY =
        coordinate - secondTooltipHeight - toolTipVMargin > 0
          ? coordinate - secondTooltipHeight - toolTipVMargin
          : Math.max(
            0,
            Math.min(
              secondChartRef.current.clientHeight - secondTooltipHeight - toolTipVMargin,
              coordinate + toolTipVMargin
            )
          );

      toolTipLine.style.left = leftLine + 'px';
      toolTipLine.style.top = coordinateY + 'px';

      
      toolTipCandels.innerHTML = `<div>
        <div style="border-radius: 8px 8px 0 0; min-height: 25px; background: #060606; padding: 8px;">
          ${`${dataCandels.time?.year}-${dataCandels.time?.month}-${dataCandels.time?.day}`}
        </div>
        <div style="padding: 8px; text-wrap: nowrap;">
          <div className="body">
            Open: ${dataCandels.open}
          </div>
          <div className="body">
            High: ${dataCandels.high}
          </div>
          <div className="body">
            Low: ${dataCandels.low}
          </div>
          <div className="body">
            Close: ${dataCandels.close}
          </div>
        </div>
      </div>`;

      const coordinateCandels = seriesCandel.priceToCoordinate(dataCandels.close);
      let shiftedCoordinateCandels = timeCandels.timeToCoordinate(dataCandels.time);
      if (coordinate === null) {
        return;
      }
      const firstTooltipWidth = firstChartTooltipRef.current.clientWidth;
      const firstTooltipHeight = firstChartTooltipRef.current.clientHeight;
      let leftCandels = shiftedCoordinateCandels + toolTipMargin;
      if (leftCandels > secondChartRef.current.clientWidth - firstTooltipWidth) {
        leftCandels = shiftedCoordinateCandels - firstTooltipWidth + 40;
      }
      const coordinateYCandels =
        coordinateCandels - firstTooltipHeight - toolTipVMargin > 0
          ? coordinateCandels - firstTooltipHeight - toolTipVMargin
          : Math.max(
            0,
            Math.min(
              secondChartRef.current.clientHeight - firstTooltipHeight - toolTipVMargin,
              coordinateCandels + toolTipVMargin
            )
          );
      toolTipCandels.style.left = leftCandels + 'px';
      toolTipCandels.style.top = coordinateYCandels + 'px';
    }
  }

	useEffect(
		() => {
			const handleResize = () => {
				firstChart.applyOptions({ width: firstChartRef.current.clientWidth });
				secondChart.applyOptions({ width: firstChartRef.current.clientWidth });
			};
      const firstChart = createChart(firstChartRef.current, {
        layout: {
          background: { type: ColorType.Solid, color: backgroundColor },
          textColor,
        },
        grid: {
          show: false,
          vertLines: { color: '#000' },
          horzLines: { color: '#000' },
        },
        leftPriceScale: {
          visible: true,
          mode: PriceScaleMode.Normal,
        },
        rightPriceScale: {
          visible: false,
        },
        width: firstChartRef.current.clientWidth,
        height: 300,
      });
      const secondChart = createChart(secondChartRef.current, {
        layout: {
          background: { type: ColorType.Solid, color: backgroundColor },
          textColor,
        },
        grid: {
          show: false,
          vertLines: { color: '#000' },
          horzLines: { color: '#000' },
        },
        leftPriceScale: {
          visible: true,
          mode: PriceScaleMode.Normal,
        },
        rightPriceScale: {
          visible: false,
        },
        width: secondChartRef.current.clientWidth,
        height: 300,
      });
      // first chart
      firstChart.timeScale().fitContent();
      const newSeries = firstChart.addCandlestickSeries();
      newSeries.applyOptions({
        wickUpColor: '#00B746',
        upColor: '#00B746',
        wickDownColor: '#EF403C',
        downColor: '#EF403C',
        borderVisible: false,
      });    
      newSeries.setData(firstData);
      // const lineChartColor = lineColor[0]
      // const newSeries1 = firstChart.addLineSeries({ color: lineChartColor, topColor: areaTopColor, bottomColor: areaBottomColor});
      // newSeries1.setData(secondData[0]);


      // second chart
      secondChart.timeScale().fitContent();

      const newSeries2 = secondChart.addLineSeries({ color: lineColor[0], topColor: areaTopColor, bottomColor: areaBottomColor});
      // const newSeries3 = secondChart.addLineSeries({ color: lineColor[1], topColor: areaTopColor, bottomColor: areaBottomColor});
      const newSeries4 = secondChart.addLineSeries({ color: lineColor[2], topColor: areaTopColor, bottomColor: areaBottomColor});
      if (secondData.length) {
        newSeries2.setData(secondData[0]);
        // newSeries3.setData(secondData[1]);
        newSeries4.setData(secondData[2]);
      }

      firstChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        secondChart.timeScale().setVisibleLogicalRange(timeRange);
      });
      
      secondChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        firstChart.timeScale().setVisibleLogicalRange(timeRange);
      });
      
      function getCrosshairDataPoint(data, series, param) {
        if (!param.time) {
          return null;
        }
        const dataPoint = param.seriesData.get(series);

        return data.find(it => {
          if (it.time?.year) {
            return `${it.time?.year}-${it.time?.month}-${it.time?.day}` === dataPoint.time
          } else {
            return `${dataPoint.time?.year}-${dataPoint.time?.month}-${dataPoint.time?.day}` === it.time
          }
        }) || null;
      }

      function syncCrosshair(chart, series, dataPoint) {
        if (dataPoint.time) {
          chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
          return;
        }
        chart.clearCrosshairPosition();
      }
      firstChart.subscribeCrosshairMove(param => {
        try {
          const dataPoint = getCrosshairDataPoint(secondData[0],newSeries, param);
          syncCrosshair(secondChart, newSeries2, dataPoint);
          setTooltipPosition(param, secondChart, newSeries2, newSeries4, firstChart, dataPoint, newSeries, 'candels')  
        } catch {
          // secondChartTooltipRef.current.style.display = 'none';
          // firstChartTooltipRef.style.display = 'none';
          // secondChart.clearCrosshairPosition();
        }
      });

      secondChart.subscribeCrosshairMove(param => {
        try {
          const dataPoint = getCrosshairDataPoint(firstData, newSeries2, param);
          syncCrosshair(firstChart, newSeries, dataPoint);
          setTooltipPosition(param, secondChart, newSeries2, newSeries4, firstChart, dataPoint, newSeries, 'line')  
        } catch {
          // secondChartTooltipRef.current.style.display = 'none';
          // firstChartTooltipRef.current.style.display = 'none';
          // firstChart.clearCrosshairPosition();
        }    
      });

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);

        firstChart.remove();
        secondChart.remove();
      };
		},
		[firstData, secondData, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
	);

	return (
    <>
      <div ref={firstChartRef} style={{position: 'relative'}}>
        <div ref={firstChartTooltipRef} />
      </div>
      <div ref={secondChartRef} style={{position: 'relative'}}>
        <div ref={secondChartTooltipRef} />
      </div>
    </>
    
	);
};

export const COT = () => {
  const [loadData, setLoadData] = useState({
    candlesData: false,
    candlesLoad: false,
    cotData: false,
    cotLoad: false,
  })
  const [graphData, setGraphData] = useState([])
  const [candelsData, setCandelsData] = useState([])
  const { activeSymbol, symbolsData } = useSelector(getSymbols)
  const dispatch = useDispatch()
  const { t } = useTranslation()
 
	const chartCandelsContainerRef = useRef();
	const chartLineContainerRef = useRef();
  //         formatter: function (val) {
  //           let formatNumber = Math.round(val * 100) / 100
  //           return formatNumber + ' %'
  //         },
  //         minWidth: 70,

  const state = {
      colors: ['#3dca7e', '#a52290'],
  };

  useEffect(() => {
    if (activeSymbol) {
      setLoadData({ ...loadData, cotLoad: true })
      Promise.all([
        getChartCOT(activeSymbol.id, 'COMMERCIAL'),
        getChartCOT(activeSymbol.id, 'NON_COMMERCIAL'),
        getChartCOT(activeSymbol.id, 'NON_REPORTABLES'),
      ])
        .then(res => {
          setGraphData(
            res.map(el => {
              return el.data.map((it) => {
                return {
                  time: it.x,
                  value: it.y,
                }
              })
            }),
          )
          setLoadData({
            ...loadData,
            cotLoad: false,
            cotData: !res
              .map(el => {
                return el.data
              })
              .flat().length,
          })
          return
        })
        .catch(e => {
          setLoadData({ ...loadData, cotData: true, cotLoad: false })
        })
    }
  }, [activeSymbol])

  useEffect(() => {
    let dataForCheckLength = graphData[0]

    if (dataForCheckLength?.length && activeSymbol) {
      setLoadData({ ...loadData, candlesLoad: true })
      getSymbolsByCandleForMarketData(
        activeSymbol.id,
        'WEEK',
        dataForCheckLength.length,
      )
        .then(res => {
          const formatData = res.data.map(el => {
            const date = el.openTime.split('-');
            return {
              time: { year: date[0], month: date[1], day: date[2] },
              open: el.openPrice,
              high: el.highPrice,
              low: el.lowPrice,
              close: el.closePrice,
            }
          })
          setCandelsData(formatData)
          setLoadData({
            ...loadData,
            candlesData: !res.data.length,
            candlesLoad: false,
          })
        })
        .catch(error => {
          console.error(error)
          setLoadData({ ...loadData, candlesData: true, candlesLoad: false })
        })
    }
    if (loadData.cotData) {
      setLoadData({ ...loadData, candlesData: true })
    }
    setCandelsData([])
  }, [graphData])

  // useEffect(() => {
  //   if (graphData.length && candelsData.length) {
  //     setGraphData((prevState) => {
        
  //       return prevState.filter(it => candelsData.some(item => 
  //         `${item.time?.year}-${item.time?.month}-${item.time?.day}` === it.time))
  //     })

  //     setCandelsData((prevState) => {
  //       return prevState.filter(it => graphData.some(item => 
  //         `${it.time?.year}-${it.time?.month}-${it.time?.day}` === item.time))
  //     })
  //   }
  // }, [graphData, candelsData])

  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header border-0 align-items-start flex-wrap pb-0">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ width: '100%' }}
            >
              <div className="d-flex align-items-center gap-3">
                <h2 className="heading">{t('commitmentOfTraders.title')}</h2>
                <h4 className="heading ">({activeSymbol?.name})</h4>
                {loadData.candlesLoad && (
                  <div
                    className="spinner-border text-success"
                    style={{ width: '25px', height: '25px' }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="chart-holder">
            <div id="visitorVsLead" className="apex-line"></div>
          </div>
          <div className="chart-holder">
            <div id="chartConversion" className="apex-vert-bar"></div>
          </div>

          <div className="card-body">
            {graphData[0]?.length ? (
              <>
                <ChartComponent
                  firstData={candelsData}
                  secondData={graphData}
                  firstChartRef={chartCandelsContainerRef}
                  secondChartRef={chartLineContainerRef}
                ></ChartComponent>
              </>
            ) : t('cot.noData')}
          </div>
          <div id="chart-candlestick">
          </div>
        </div>
      </div>
    </>
  )
}
