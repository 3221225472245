import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, Tab, Nav } from 'react-bootstrap'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import { getSymbols } from '../../../store/selectors/SymbolsSelectors'
import { useTranslation } from 'react-i18next'
import DashboardComboChart from '../Dashboard/Dashboard/DashboardComboChart'
import { getChartCampusValuation, getChartSeasonality, getSymbolsByCandleForMarketData } from '../../../services/PostsService'
import { setLoading } from '../../../store/actions/LoadingActions'
import Select from 'react-select';
import ReactApexChart from 'react-apexcharts'
import { ColorType, PriceScaleMode, createChart } from 'lightweight-charts'
const moment = require('moment')
const IntradayChart = loadable(() => pMinDelay(import('./IntradayChart'), 1000))

const depth = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 20, label: 20 },
  { value: 25, label: 25 },
  { value: 30, label: 30 },
  { value: 60, label: 60 },
  { value: 90, label: 90 },
  { value: 120, label: 120 },
]
const precision = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
]
const dateOptions = [
  { value: 'DAY', label: 'Day' },
  { value: 'WEEK', label: 'Week' },
  { value: 'MONTH', label: 'Month' },
]

export const ChartComponent = props => {
  const [tooltipData, setTooltipData] = useState({});
	const {
		candelData,
    symbol1,
    symbol2,
    symbol3,
		secondData,
    firstChartRef,
    secondChartRef,
    upperLimit,
    bottomLimit,
    syncCrosshairChart,
    syncCrosshairChartTime,
		colors: {
			backgroundColor = '#000',
			lineColor = ['#3dca7e', '#a52290', '#cbcf1f'],
			textColor = 'white',
			areaTopColor = '#2962FF',
			areaBottomColor = 'rgba(41, 98, 255, 0.28)',
		} = {},
	} = props;
  const firstChartTooltipRef = useRef();
  const secondChartTooltipRef = useRef();
  const { t } = useTranslation()

  const { alignedData1, alignedData2, alignedData3, alignedData4 } = alignDataArrays(candelData,
    symbol1?.data,
    symbol2?.data,
    symbol3?.data);

  const setTooltipPosition = (param, lineChart, firstSeriesLine, secondSeriesLine, thirdSeriesLine, candelsChart, secondParam, seriesCandel, type) => {

    const toolTipWidth = 100;
    const toolTipHeight = 80;
    const toolTipMargin = 70;
    const toolTipVMargin = 20;
    // Create and style the tooltip html element
    const toolTipLine = secondChartTooltipRef.current;
    const toolTipCandels = firstChartTooltipRef.current;
    ///
    const timeCandels = candelsChart.timeScale();
    const timeLine = lineChart.timeScale();

    if (
      param.point === undefined ||
      !param.time ||
      param.point.x < 0 ||
      param.point.x > firstChartRef.current.clientWidth ||
      param.point.y < 0 ||
      param.point.y > firstChartRef.current.clientHeight
    ) {
      toolTipLine.style.display = 'none';
      toolTipCandels.style.display = 'none';
    } else {
      let lineTime;
      let price1;
      let price2;
      let price3;
      //
      let dataLine = {};
      let dataCandels = {};

      if (type == 'line') {
        dataLine.firstDataLine = param.seriesData.get(firstSeriesLine);
        dataLine.secondDataLine = param.seriesData.get(secondSeriesLine);
        dataLine.thirdSeriesLine = param.seriesData.get(thirdSeriesLine);

        dataCandels = secondParam;
        
        lineTime = param.time;
        price1 = dataLine.firstDataLine?.value !== undefined ? dataLine.firstDataLine?.value : dataLine.firstDataLine?.close;
        price2 = dataLine.secondDataLine?.value !== undefined ? dataLine.secondDataLine?.value : dataLine.secondDataLine?.close;
        price3 = dataLine.thirdSeriesLine?.value !== undefined ? dataLine.thirdSeriesLine?.value : dataLine.thirdSeriesLine?.close;
      } else {
        dataLine.firstDataLine = alignedData2?.find(it => it.time == secondParam.time);
        dataLine.secondDataLine = alignedData3?.find(it => it.time == secondParam.time);
        dataLine.thirdSeriesLine = alignedData4?.find(it => it.time == secondParam.time);

        lineTime = secondParam?.time;

        price1 = dataLine.firstDataLine?.value;
        price2 = dataLine.secondDataLine?.value;
        price3 = dataLine.thirdSeriesLine?.value;
        dataCandels = param.seriesData.get(seriesCandel)
      }

      //

      toolTipCandels.style = `transform: 0.5s all; min-width: 96px; min-height: 80px; position: absolute; display: none; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 8px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
      toolTipCandels.style.background = '#060606cc';
      toolTipCandels.style.color = 'white';
      toolTipCandels.style.borderColor = '#000';
      toolTipCandels.style.display = 'block';


      if (secondParam) {
        toolTipLine.style = `transform: 0.5s all; min-width: 96px; min-height: 80px; position: absolute; display: none; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 8px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
        toolTipLine.style.background = '#060606cc';
        toolTipLine.style.color = 'white';
        toolTipLine.style.borderColor = '#000';
        toolTipLine.style.display = 'block';
      
        // const price3 = dataCandels.value !== undefined ? dataCandels.value : dataCandels.close;
        toolTipLine.innerHTML = `<div>
          <div style="border-radius: 8px 8px 0 0; min-height: 25px; background: #060606; padding: 8px;">
            ${lineTime}
          </div>
          <div style="padding: 8px;text-wrap: nowrap;">
            ${price1 ? `<div className="body" style="display: flex;">
            <div style="border-radius: 50%; background: ${lineColor[0]}; margin-top: 3px; margin-right: 3px; width: 10px; height: 10px;"></div>
              ${symbol1?.name}: ${price1}
            </div>` : ''}
            ${price2 ? `<div className="body" style="display: flex;">
              <div style="border-radius: 50%; background: ${lineColor[1]}; margin-top: 3px; margin-right: 3px; width: 10px; height: 10px;"></div>
              ${symbol2?.name}: ${price2}
            </div>` : ''}
            ${price3 ? `<div className="body" style="display: flex;">
              <div style="border-radius: 50%; background: ${lineColor[2]}; margin-top: 3px; margin-right: 3px; width: 10px; height: 10px;"></div>
              ${symbol3?.name}: ${price3}
            </div>` : ''}
          </div>
        </div>`;
        let coordinate;
        if (price1) {
          coordinate = firstSeriesLine.priceToCoordinate(price1);
        } else if (price2) {
          coordinate = secondSeriesLine.priceToCoordinate(price2);
        } else if (price3) {
          coordinate = thirdSeriesLine.priceToCoordinate(price3);
        }
        let shiftedCoordinate = timeLine.timeToCoordinate(lineTime);

        if (coordinate === null) {
          return;
        }
        const secondTooltipWidth = secondChartTooltipRef.current.clientWidth;
        const secondTooltipHeight = secondChartTooltipRef.current.clientHeight;
        let leftLine = shiftedCoordinate + toolTipMargin;
        if (leftLine > secondChartRef.current.clientWidth - secondTooltipWidth) {
          leftLine = shiftedCoordinate - secondTooltipWidth + 40;
        }
        const coordinateY =
          coordinate - secondTooltipHeight - toolTipVMargin > 0
            ? coordinate - secondTooltipHeight - toolTipVMargin
            : Math.max(
              0,
              Math.min(
                secondChartRef.current.clientHeight - secondTooltipHeight - toolTipVMargin,
                coordinate + toolTipVMargin
              )
            );
  
        toolTipLine.style.left = leftLine + 'px';
        toolTipLine.style.top = coordinateY + 'px';
      }
      
      toolTipCandels.innerHTML = `<div>
        <div style="border-radius: 8px 8px 0 0; min-height: 25px; background: #060606; padding: 8px;">
          ${`${dataCandels.time?.year}-${dataCandels.time?.month}-${dataCandels.time?.day}`}
        </div>
        <div style="padding: 8px; text-wrap: nowrap;">
          <div className="body">
            Open: ${dataCandels.open}
          </div>
          <div className="body">
            High: ${dataCandels.high}
          </div>
          <div className="body">
            Low: ${dataCandels.low}
          </div>
          <div className="body">
            Close: ${dataCandels.close}
          </div>
        </div>
      </div>`;

      const coordinateCandels = seriesCandel.priceToCoordinate(dataCandels.close);
      let shiftedCoordinateCandels = timeCandels.timeToCoordinate(dataCandels.time);
      if (coordinateCandels === null) {
        return;
      }
      const firstTooltipWidth = firstChartTooltipRef.current.clientWidth;
      const firstTooltipHeight = firstChartTooltipRef.current.clientHeight;
      let leftCandels = shiftedCoordinateCandels + toolTipMargin;
      if (leftCandels > firstChartRef.current.clientWidth - firstTooltipWidth) {
        leftCandels = shiftedCoordinateCandels - firstTooltipWidth + 40;
      }
      const coordinateYCandels =
        coordinateCandels - firstTooltipHeight - toolTipVMargin > 0
          ? coordinateCandels - firstTooltipHeight - toolTipVMargin
          : Math.max(
            0,
            Math.min(
              firstChartRef.current.clientHeight - firstTooltipHeight - toolTipVMargin,
              coordinateCandels + toolTipVMargin
            )
          );
      toolTipCandels.style.left = leftCandels + 'px';
      toolTipCandels.style.top = coordinateYCandels + 'px';
    }
  }

	useEffect(
		() => {
			const handleResize = () => {
				firstChart.applyOptions({ width: firstChartRef.current.clientWidth });
				secondChart.applyOptions({ width: firstChartRef.current.clientWidth });
			};
      const firstChart = createChart(firstChartRef.current, {
        layout: {
          background: { type: ColorType.Solid, color: backgroundColor },
          textColor,
        },
        grid: {
          show: false,
          vertLines: { color: '#000' },
          horzLines: { color: '#000' },
        },
        leftPriceScale: {
          visible: true,
          mode: PriceScaleMode.Normal,
        },
        rightPriceScale: {
          visible: false,
        },
        width: firstChartRef.current.clientWidth,
        height: 300,
      });
      const secondChart = createChart(secondChartRef.current, {
        layout: {
          background: { type: ColorType.Solid, color: backgroundColor },
          textColor,
        },
        grid: {
          show: false,
          vertLines: { color: '#000' },
          horzLines: { color: '#000' },
        },
        leftPriceScale: {
          visible: true,
          mode: PriceScaleMode.Normal,
        },
        rightPriceScale: {
          visible: false,
        },
        width: secondChartRef?.current?.clientWidth,
        height: (alignedData2?.length || alignedData3?.length || alignedData4?.length) ? 300 : 0,
      });
      // first chart
      firstChart.timeScale().fitContent();
      const newSeries = firstChart.addCandlestickSeries();
      newSeries.applyOptions({
        wickUpColor: '#00B746',
        upColor: '#00B746',
        wickDownColor: '#EF403C',
        downColor: '#EF403C',
        borderVisible: false,
      });

      newSeries.setData(candelData);

      secondChart.timeScale().fitContent();

      const symbalSeries1 = secondChart.addLineSeries({ color: lineColor[0], topColor: areaTopColor, bottomColor: areaBottomColor});
      const symbalSeries2 = secondChart.addLineSeries({ color: lineColor[1], topColor: areaTopColor, bottomColor: areaBottomColor});
      const symbalSeries3 = secondChart.addLineSeries({ color: lineColor[2], topColor: areaTopColor, bottomColor: areaBottomColor});

      const lineWidth = 2;
      const minPriceLine = {
        price: Number(bottomLimit),
        color: '#0bffac',
        lineWidth: lineWidth,
        lineStyle: 2, // LineStyle.Dashed
        axisLabelVisible: true,
        title: t('campusValuation.bottomLimit'),
      };
      const maxPriceLine = {
        price: Number(upperLimit),
        color: '#fc5050',
        lineWidth: lineWidth,
        lineStyle: 2, // LineStyle.Dashed
        axisLabelVisible: true,
        title: t('campusValuation.upperLimit'),
      };

      if (alignedData2?.length) {
        try {
          symbalSeries1?.setData(alignedData2);
        } catch {
          symbalSeries1?.setData(alignedData2.reverse());
        }
      } else {
        try {
          symbalSeries1?.removePriceLine()
        } catch {}
      }
      if (alignedData3?.length) {
        try {
          symbalSeries2?.setData(alignedData3);
        } catch {
          symbalSeries2?.setData(alignedData3.reverse());
        }
      } else {
        try {
          symbalSeries2?.removePriceLine()
        } catch {}
      }
      if (alignedData4?.length) {
        try {
          symbalSeries3?.setData(alignedData4);
        } catch {
          symbalSeries3?.setData(alignedData4.reverse());
        }
      } else {
        try {
          symbalSeries3?.removePriceLine()
        } catch {}
      }

      if (alignedData2?.length) {
        symbalSeries1.createPriceLine(minPriceLine);
        symbalSeries1.createPriceLine(maxPriceLine);
      } else if (alignedData3?.length) {
        symbalSeries2.createPriceLine(minPriceLine);
        symbalSeries2.createPriceLine(maxPriceLine);
      } else if (alignedData4?.length) {
        symbalSeries3.createPriceLine(minPriceLine);
        symbalSeries3.createPriceLine(maxPriceLine);
      }

      firstChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        secondChart.timeScale().setVisibleLogicalRange(timeRange);
      });
      
      secondChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        firstChart.timeScale().setVisibleLogicalRange(timeRange);
      });
      
      function getCrosshairDataPoint(data, series, param) {
        if (!param.time) {
          return null;
        }
        const dataPoint = param.seriesData.get(series);

        return data.find(it => {
          if (it.time?.year) {
            return `${it.time?.year}-${it.time?.month}-${it.time?.day}` === dataPoint.time
          } else {
            return `${dataPoint.time?.year}-${dataPoint.time?.month}-${dataPoint.time?.day}` === it.time
          }
        }) || null;
      }

      function syncCrosshair(chart, series, dataPoint) {
        if (dataPoint?.time) {
          chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
          return;
        }
        chart.clearCrosshairPosition();
      }
      firstChart.subscribeCrosshairMove(param => {
        try {
          let dataPoint;
          if (alignedData2?.length) {
            dataPoint = getCrosshairDataPoint(alignedData2,newSeries, param);
          }
          if (alignedData3?.length) {
            dataPoint = getCrosshairDataPoint(alignedData3,newSeries, param);
          }
          if (alignedData4?.length) {
            dataPoint = getCrosshairDataPoint(alignedData4,newSeries, param);
          }
          syncCrosshair(secondChart, symbalSeries1, dataPoint);
          setTooltipPosition(param, secondChart, symbalSeries1, symbalSeries2, symbalSeries3, firstChart, dataPoint, newSeries, 'candels')  
        } catch {
          // secondChartTooltipRef.current.style.display = 'none';
          // firstChartTooltipRef.style.display = 'none';
          // secondChart.clearCrosshairPosition();
          // setTooltipPosition(param, secondChart, symbalSeries1, symbalSeries2, symbalSeries3, firstChart, undefined, newSeries, 'candels')  
        }
      });

      secondChart.subscribeCrosshairMove(param => {
        try {
          let dataPoint;
          if (alignedData2?.length) {
            dataPoint = getCrosshairDataPoint(candelData, symbalSeries1, param);
          } else if (alignedData3?.length) {
            dataPoint = getCrosshairDataPoint(candelData, symbalSeries2, param);
          } else if (alignedData4?.length) {
            dataPoint = getCrosshairDataPoint(candelData, symbalSeries3, param);
          }
          syncCrosshair(firstChart, newSeries, dataPoint);
          setTooltipPosition(param, secondChart, symbalSeries1, symbalSeries2, symbalSeries3, firstChart, dataPoint, newSeries, 'line')  

        } catch {
          // secondChartTooltipRef.current.style.display = 'none';
          // firstChartTooltipRef.current.style.display = 'none';
          // firstChart.clearCrosshairPosition();
          // setTooltipPosition(param, secondChart, symbalSeries1, symbalSeries2, symbalSeries3, firstChart, undefined, newSeries, 'line')  
        }    
      });

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);

        firstChart.remove();
        secondChart.remove();
      };
		},
		[candelData, symbol1, symbol2, symbol3, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
	);

	return (
    <>
      <div ref={firstChartRef} style={{position: 'relative'}}>
        <div ref={firstChartTooltipRef} />
      </div>
      {/* {symbol1?.data.length || symbol2?.data.length || symbol3?.data.length ? ( */}
        <div ref={secondChartRef} style={{position: 'relative'}}>
          <div ref={secondChartTooltipRef} />
        </div>
        {/* ) : t('campusValuation.selectSymbol')} */}
    </>
    
	);
};

function alignDataArrays(data1, data2, data3, data4) {
  const allDates = new Set();
  data1?.forEach(item => allDates.add(`${item.time.year}-${item.time.month}-${item.time.day}`));
  data2?.forEach(item => allDates.add(item.time));
  data3?.forEach(item => allDates.add(item.time));
  data4?.forEach(item => allDates.add(item.time));

  const sortedDates = Array.from(allDates).sort((a, b) => a - b);

  function fillData(data, dates) {
    if (data) {
      const dateMap = new Map(data.map(item => [item.time, item.value]));
      return dates.map(date => ({
          time: date,
          value: dateMap.has(date) ? dateMap.get(date) : undefined,
      }));
    } else {
      return undefined;
    }
  }

  const alignedData1 = data1.length ? data1.length : undefined;
  const alignedData2 = fillData(data2, sortedDates);
  const alignedData3 = fillData(data3, sortedDates);
  const alignedData4 = fillData(data4, sortedDates);

  return { alignedData1, alignedData2, alignedData3, alignedData4 };
}

const CampusValuation = () => {
  const { t, i18n } = useTranslation()

  const [data, setData] = useState(
    document.querySelectorAll('#status_wrapper tbody tr'),
  ) 
  const [test, settest] = useState(0)
  const [graphData, setGraphData] = useState([])
  const [symbalData, setSympolData] = useState();
  const [candelsData, setCandelsData] = useState([])
  const { activeSymbol, symbolsData } = useSelector(getSymbols)
  const [selectedPrecision, setSelectedPrecision] = useState(precision[3]);
  const [selectedDepth, setSelectedDepth] = useState(depth[7]);
  const [symbol1, setSymbol1] = useState();
  const [symbol2, setSymbol2] = useState();
  const [symbol3, setSymbol3] = useState();
  const [sympolList1, setSympolList1] = useState();
  const [sympolList2, setSympolList2] = useState();
  const [sympolList3, setSympolList3] = useState();
  const [upperLimit, setUpperLimit] = useState(75);
  const [bottomLimit, setBottomLimit] = useState(-75);
  const [datePeriod, setDatePeriod] = useState({ value: 'DAY', label: 'Day' });

	const chartCandelsContainerRef = useRef();
	const chartLineContainerRef = useRef();

  const dispatch = useDispatch()
  const sort = 6
  const activePag = useRef(0)

  useEffect(() => {
    if (activeSymbol) {
      const filteredSymbolsData = symbolsData.filter(it => it.id != activeSymbol.id);
      setSympolList1([{name: 'Empty', value: null}, ...filteredSymbolsData]);
      setSympolList2([{name: 'Empty', value: null}, ...filteredSymbolsData]);
      setSympolList3([{name: 'Empty', value: null}, ...filteredSymbolsData]);
    }
  }, [activeSymbol]);

  useEffect(() => {
    if (activeSymbol && selectedDepth && selectedPrecision && datePeriod && (symbol1 || symbol2 || symbol3)) {
      dispatch(setLoading(true))
      getCampusValuation(symbol1, 'symbol1');
      getCampusValuation(symbol2, 'symbol2');
      getCampusValuation(symbol3, 'symbol3');

    }
  }, [activeSymbol, selectedDepth, selectedPrecision, symbol1, symbol2, symbol3, datePeriod])

  useEffect(() => {
    if(symbalData) {
      setGraphData(Object.keys(symbalData).map((it) => symbalData[it]));
    }
  }, [symbalData?.symbol1, symbalData?.symbol2, symbalData?.symbol3]);


  const getCampusValuation = (symbol, symbolName) => {
    if (symbol?.value) {
      const date = new Date();
      date.setFullYear(date.getFullYear() - (datePeriod.value === 'DAY' ? 3 : datePeriod.value === 'WEEK' ? 10 : 30));

      getChartCampusValuation({
        symbolId: activeSymbol.id,
        referenceSymbolId: symbol.value,
        depth: selectedDepth.value,
        precision: selectedPrecision.value,
        fromDate: moment(date).format('yyyy-MM-DD'),
        candleSize: datePeriod.value
      })
        .then(res => {
          setSympolData((prevState) => {
            return {
              ...prevState,
              [symbolName]: {
                name: symbol.label,
                data: res.data.reverse().map((it) => {
                  return {
                    time: it.x,
                    value: it.y,
                  }
                })
              }
            }
          })
          // setSympol1GraphData({
          //     name: this[sympol].label,
          //     data: res.data
        // })
          dispatch(setLoading(false))
        })
        .catch(error => {
          console.error(error)
          dispatch(setLoading(false))
        })
    } else {
      setSympolData((prevState) => {
        if (symbolName === 'symbol1' && prevState?.symbol1) {
          const {symbol1, ...state} = prevState;
          return state;
        }
        if (symbolName === 'symbol2' && prevState?.symbol2) {
          const {symbol2, ...state} = prevState;
          return state;
        }
        if (symbolName === 'symbol3' && prevState?.symbol3) {
          const {symbol3, ...state} = prevState;
          return state;
        } 
        return prevState;
      })
      dispatch(setLoading(false))
    }
  }

  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove('d-none')
      } else {
        data[i].classList.add('d-none')
      }
    }
  }

  useEffect(() => {
    if (activeSymbol) {
      
      const date = new Date();
      date.setFullYear(date.getFullYear() - (datePeriod.value === 'DAY' ? 3 : datePeriod.value === 'WEEK' ? 10 : 30));
      dispatch(setLoading(true))
      getSymbolsByCandleForMarketData(
        activeSymbol.id,
        datePeriod.value,
        undefined,
        date.toISOString()
      )
        .then(res => {
          const formatData = res.data.map(el => {
            const date = el.openTime.split('-');
            return {
              time: { year: date[0], month: date[1], day: date[2] },
              open: el.openPrice,
              high: el.highPrice,
              low: el.lowPrice,
              close: el.closePrice,
            }
          })
          setCandelsData(formatData)
          dispatch(setLoading(false))
        })
        .catch(error => {
          dispatch(setLoading(false))
        })
    }
    setCandelsData([])
  }, [datePeriod, activeSymbol])

  useEffect(() => {
    setData(document.querySelectorAll('#status_wrapper tbody tr'))
    //chackboxFun();
  }, [test])

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort)


  const sympol1Options = useMemo(() => {
    return sympolList1?.map((el, i) => {
      return {value: el.id, label: el.name}
    })
  }, [sympolList1]);

  const sympol2Options = useMemo(() => {
    return sympolList2?.map((el, i) => {
      return {value: el.id, label: el.name}
    })
  }, [sympolList2]);

  const sympol3Options = useMemo(() => {
    return sympolList3?.map((el, i) => {
      return {value: el.id, label: el.name}
    })
  }, [sympolList3]);

  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header border-0 align-items-start flex-wrap pb-0">
            <div className="d-flex align-items-center gap-3">
              <h2 className="heading">{t('campusValuation.title')}</h2>
              <h2 className="heading">({activeSymbol?.name})</h2>
              <div className="market-data"></div>
            </div>
          </div>
          <div className="card-body pb-0">
              <div className="d-flex flex-wrap">
                <div className="d-flex flex-wrap">
                  <div className='ms-2' style={{flex: '1 1 auto'}}>
                    <label className="form-label text-primary">{t('campusValuation.symbol1')}</label>
                    <Select
                        defaultValue={symbol1}
                        name={'symbol1'}
                        options={sympol1Options}
                        className="custom-react-select me-2 mb-4 w-100"
                        onChange={setSymbol1}
                    />
                  </div>
                  <div className='ms-2' style={{flex: '1 1 auto'}}>
                    <label className="form-label text-primary">{t('campusValuation.symbol2')}</label>
                    <Select
                        defaultValue={symbol2}
                        name={'symbol2'}
                        options={sympol2Options}
                        className="custom-react-select me-2 mb-4 w-100"
                        onChange={setSymbol2}
                    />
                  </div>
                  <div className='ms-2' style={{flex: '1 1 auto'}}>
                    <label className="form-label text-primary">{t('campusValuation.symbol3')}</label>
                    <Select 
                        defaultValue={symbol3} 
                        name={'symbol3'}
                        options={sympol3Options}
                        className="custom-react-select me-2 mb-4 w-100"
                        onChange={setSymbol3}
                    />
                  </div>
                </div>
                <div className="d-flex flex-wrap">
                  <div className='ms-2' style={{flex: '1 1 auto'}}>
                    <label className="form-label text-primary">{t('campusValuation.depth')}</label>
                    <Select 
                        options={depth} 
                        defaultValue={selectedDepth}
                        className="custom-react-select me-2 mb-4 w-100"
                        onChange={setSelectedDepth}
                    />
                  </div>
                  <div className='ms-2' style={{flex: '1 1 auto'}}>
                    <label className="form-label text-primary">{t('campusValuation.precision')}</label>
                    <Select 
                        options={precision} 
                        defaultValue={selectedPrecision}
                        className="custom-react-select me-2 mb-4 w-100"
                        onChange={setSelectedPrecision}
                    />
                  </div>
                  <div className='ms-2' style={{flex: '1 1 auto'}}>
                    <label className="form-label text-primary">{t('campusValuation.period')}</label>
                    <Select 
                        style={{width: '140px'}}
                        options={dateOptions} 
                        defaultValue={datePeriod}
                        className="custom-react-select me-2 mb-4 w-100"
                        onChange={setDatePeriod}
                    />
                  </div>
                </div>

                  {graphData.length ? (
                    <div className="d-flex flex-wrap">
                      <div className='ms-2' style={{flex: '1 1 auto'}}>
                        <label className="form-label text-primary">{t('campusValuation.upperLimit')}</label>
                        <div className="input-group">
                          <input value={upperLimit} type="number" className="form-control" onChange={(e) => setUpperLimit(e.target.value ? e.target.value : 0)}/>
                        </div>
                      </div>
                      <div className='ms-2' style={{flex: '1 1 auto'}}>
                        <label className="form-label text-primary">{t('campusValuation.bottomLimit')}</label>
                        <div className="input-group">
                          <input value={bottomLimit} type="number" className="form-control" onChange={(e) => setBottomLimit(e.target.value ? e.target.value : 0)}/>
                        </div>
                      </div>
                    </div>
                    ) : (<></>)}
              </div>
          </div>
          <div className="card-body">
            <ChartComponent
              candelData={candelsData}
              symbol1={symbalData?.symbol1}
              symbol2={symbalData?.symbol2}
              symbol3={symbalData?.symbol3}
              upperLimit={upperLimit}
              bottomLimit={bottomLimit}
              // secondData={graphData}
              firstChartRef={chartCandelsContainerRef}
              secondChartRef={chartLineContainerRef}
            ></ChartComponent>
            {/* {graphData?.length ? (
              <ReactApexChart
                options={state.options}
                series={state.series}
                height={350}
              />
            ) : t('campusValuation.selectSymbol')} */}
          </div>
        </div>
      </div>
    </>
  )
}
export default CampusValuation
