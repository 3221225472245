import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getSymbols } from '../../../store/selectors/SymbolsSelectors'
import { useTranslation } from 'react-i18next'
import {
  getChartSeasonality,
  getSymbolsByCandleForMarketData,
} from '../../../services/PostsService'
import Form from 'react-bootstrap/Form'
import ReactApexChart from 'react-apexcharts'
import Select from 'react-select';
import { usePeriods } from '../../hooks/usePeriods'
import { ColorType, PriceScaleMode, createChart } from 'lightweight-charts'
const moment = require('moment')

export const ChartComponent = props => {
	const {
		secondData,
    secondChartRef,
    activeSymbolName,
    setCurrentDay,
		colors: {
			backgroundColor = '#000',
			lineColor = ['#3dca7e', '#a52290'],
			textColor = 'white',
			areaTopColor = '#2962FF',
			areaBottomColor = 'rgba(41, 98, 255, 0.28)',
		} = {},
	} = props;
  const firstChartTooltipRef = useRef();
  const secondChartTooltipRef = useRef();
  const { t } = useTranslation()

  const setTooltipPosition = (param, lineChart, firstSeriesLine, type) => {

    const toolTipWidth = 100;
    const toolTipHeight = 80;
    const toolTipMargin = 70;
    const toolTipVMargin = 20;
    // Create and style the tooltip html element
    const toolTipLine = secondChartTooltipRef.current;
    const toolTipCandels = firstChartTooltipRef.current;
    ///
    const timeLine = lineChart.timeScale();

    if (
      param.point === undefined ||
      !param.time ||
      param.point.x < 0 ||
      param.point.x > secondChartRef.current.clientWidth ||
      param.point.y < 0 ||
      param.point.y > secondChartRef.current.clientHeight
    ) {
      toolTipLine.style.display = 'none';
    } else {
      let lineTime;
      let price1;
      //
      let dataLine = {};

      if (type == 'line') {
        dataLine.firstDataLine = param.seriesData.get(firstSeriesLine);
        
        lineTime = param.time;
        price1 = dataLine.firstDataLine?.value !== undefined ? dataLine.firstDataLine?.value : dataLine.firstDataLine?.close;

        toolTipLine.style = `transform: 0.5s all; min-width: 96px; min-height: 80px; position: absolute; display: none; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 8px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
        toolTipLine.style.background = '#060606cc';
        toolTipLine.style.color = 'white';
        toolTipLine.style.borderColor = '#000';
        toolTipLine.style.display = 'block';
      
        // const price3 = dataCandels.value !== undefined ? dataCandels.value : dataCandels.close;
        toolTipLine.innerHTML = `<div>
          <div style="border-radius: 8px 8px 0 0; min-height: 25px; background: #060606; padding: 8px;">
            ${lineTime}
          </div>
          <div style="padding: 8px;text-wrap: nowrap;">
            ${price1 ? `<div className="body" style="display: flex;">
            <div style="border-radius: 50%; background: ${lineColor[0]}; margin-top: 3px; margin-right: 3px; width: 10px; height: 10px;"></div>
              ${activeSymbolName}: ${price1}
            </div>` : ''}
          </div>
        </div>`;
        let coordinate;
        if (price1) {
          coordinate = firstSeriesLine.priceToCoordinate(price1);
        }
        let shiftedCoordinate = timeLine.timeToCoordinate(lineTime);

        if (coordinate === null) {
          return;
        }
        const secondTooltipWidth = secondChartTooltipRef.current.clientWidth;
        const secondTooltipHeight = secondChartTooltipRef.current.clientHeight;
        let leftLine = shiftedCoordinate + toolTipMargin;
        if (leftLine > secondChartRef.current.clientWidth - secondTooltipWidth) {
          leftLine = shiftedCoordinate - secondTooltipWidth + 40;
        }
        const coordinateY =
          coordinate - secondTooltipHeight - toolTipVMargin > 0
            ? coordinate - secondTooltipHeight - toolTipVMargin
            : Math.max(
              0,
              Math.min(
                secondChartRef.current.clientHeight - secondTooltipHeight - toolTipVMargin,
                coordinate + toolTipVMargin
              )
            );
  
        toolTipLine.style.left = leftLine + 'px';
        toolTipLine.style.top = coordinateY + 'px';
      }
    }
  }

	useEffect(
		() => {
			const handleResize = () => {
				secondChart.applyOptions({ width: secondChartRef.current.clientWidth });
			};
      const secondChart = createChart(secondChartRef.current, {
        layout: {
          background: { type: ColorType.Solid, color: backgroundColor },
          textColor,
        },
        grid: {
          show: false,
          vertLines: { color: '#000' },
          horzLines: { color: '#000' },
        },
        leftPriceScale: {
          visible: true,
          mode: PriceScaleMode.Normal,
        },
        rightPriceScale: {
          visible: false,
        },
        width: secondChartRef.current.clientWidth,
        height: 300,
      });
      // second chart
      secondChart.timeScale().fitContent();

      const seriesLine = secondChart.addLineSeries({ color: lineColor[0], topColor: areaTopColor, bottomColor: areaBottomColor});
      seriesLine.setData(secondData);

      seriesLine.setMarkers(setCurrentDay ? [{
        time: moment().format('yyyy-MM-DD'),
        position: 'inBar',
        color: 'white',
        shape: 'circle',
        text: moment().format('yyyy MMM D'),
        size: 1,
      }] : []);

      secondChart.subscribeCrosshairMove(param => {
        try {
          setTooltipPosition(param, secondChart, seriesLine, 'line')  
        } catch {
          setTooltipPosition(param, secondChart, seriesLine, 'line')  
        }
      });

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);

        secondChart.remove();
      };
		},
		[secondData, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor, setCurrentDay]
	);

	return (
    <>
      <div ref={secondChartRef} style={{position: 'relative'}}>
        <div ref={secondChartTooltipRef} />
      </div>
    </>
    
	);
};

const IntradayTrading = () => {
  const { dataPeriods, setDataPeriods } = usePeriods()
  const [period, setPeriod] = useState(dataPeriods[0])
  const [currentDay, setCurrentDay] = useState(false)
  const [loadingData, setLoadingData] = useState({
    candlesLoad: false,
    candlesNoData: false,
    seasonLoad: false,
    seasonNoData: false,
  })
  const [graphData, setGraphData] = useState([])
  const { activeSymbol, symbolsData } = useSelector(getSymbols)
  const { t } = useTranslation()

	const chartLineContainerRef = useRef();

  const handleChangePeriod = e => {
    let targetValue = e.value;
    dataPeriods.some(el => {
      if (el.depth === targetValue) {
        return setPeriod(el)
      }
    })
  }
  useEffect(() => {
    if (activeSymbol) {
      setLoadingData({ ...loadingData, seasonLoad: true })
      getChartSeasonality(activeSymbol.id, period.depth)
        .then(res => {
          setGraphData(res.data.sort((a, b) => new Date(a.x) - new Date(b.x)).map((it) => {
              return {
                time: it.x,
                value: it.y,
              }
          }))
          setLoadingData({
            ...loadingData,
            seasonLoad: false,
            seasonNoData: !res.data.length,
          })
        })
        .catch(error => {
          console.error(error)
          setLoadingData({
            ...loadingData,
            seasonLoad: false,
            seasonNoData: true,
          })
        })
    }
  }, [activeSymbol, period.depth])

  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header border-0 align-items-start flex-wrap pb-0">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ width: '100%' }}
            >
              <div className="d-flex align-items-center gap-3">
                <h2 className="heading m-0">{t('nav.seasonality')}</h2>
                <h2 className="heading m-0">({activeSymbol?.name})</h2>
                {loadingData.candlesLoad && (
                  <div
                    className="spinner-border text-success"
                    style={{ width: '25px', height: '25px' }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center gap-3">
                <Form.Switch
                  reverse
                  type="switch"
                  id="custom-switch"
                  label="Current Day"
                  checked={currentDay}
                  isValid={currentDay}
                  onChange={() => {
                    setCurrentDay(prev => !prev)
                  }}
                />
                <Select
                  defaultValue={{value: period.depth, label: period.name}}
                  name={'name'}
                  options={dataPeriods.map(it => ({
                    value: it.depth,
                    label: it.name,
                    name: it.name,
                    depth: it.depth,
                    time: it.time
                  }))}
                  className="custom-react-select w-100"
                  onChange={handleChangePeriod}
                />
              </div>
            </div>
          </div>
          <div className="card-body">
            {graphData.length ? (
              <ChartComponent
                secondData={graphData}
                setCurrentDay={currentDay}
                activeSymbolName={activeSymbol?.name}
                secondChartRef={chartLineContainerRef}
              ></ChartComponent>
            ) : t('seasonality.noData')}
          </div>
        </div>
      </div>
    </>
  )
}
export default IntradayTrading
